import React from 'react';
import { MDBIcon } from 'mdbreact';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import BasicLabel from './BasicLabel/BasicLabel';
import './shared.scss';

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 100,
});

export default class SearchSelectField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mandatoryWarning: "Please complete this mandatory field"
        }
    }

    render() {
        let error = (this.props.showMandatory && (!this.props.searchSelect ? !this.props?.value.value : this.props?.detail_selected ? false : true)) || false;
        return (
            (!this.props.searchSelect ?
                this.props.multiple ?
                    <div className={`SearchSelectFieldContainer ${this.props?.className} `}>
                        {this.props?.label && <BasicLabel text={this.props?.label || ""} type={"text"}
                            isMandatory={this.props?.isMandatory || false} />}
                        <Autocomplete
                            className={`${error ? "error" : ""}`}
                            id={this.props?.id} multiple limitTags={this.props.limitTags || 1}
                            disabled={this.props?.disabled ? this.props?.disabled : false}
                            options={this.props?.options ? this.props?.options : []}
                            value={this.props?.value ? this.props?.value : null}
                            getOptionLabel={(option) => option.text ? option.text : ''}
                            getOptionSelected={(option, value) => option.value === value}
                            filterOptions={filterOptions}
                            popupIcon={(this.props.icon === 'hide') ? '' : <MDBIcon icon="chevron-right" size='sm' />}
                            onChange={this.props?.onChange}
                            inputValue={this.props?.manageInputValue ? this.props?.inputValue : undefined}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    required={this.props?.isMandatory || false}
                                    placeholder={this.props?.placeholder}
                                    onChange={this.props?.searchText}
                                />
                            }
                        />
                        {error && <div className="errorText">{(this.props.showMandatory && this.props?.value?.length === 0) ? this.state.mandatoryWarning : ""}</div>}
                    </div> :
                    <div 
                        className={`${this.props.className ?
                            `SearchSelectFieldContainer ${this.props.className}` :
                            `SearchSelectFieldContainer `} `}
                    >
                        {this.props?.label && <BasicLabel text={this.props?.label || ""} type={"text"}
                            isMandatory={this.props?.isMandatory || false} />}
                        <Autocomplete
                            className={`${error ? "error" : ""}`}
                            id={this.props?.id} limitTags={1}
                            disabled={this.props?.disabled ? this.props?.disabled : false}
                            options={this.props?.options ? this.props?.options : []}
                            getOptionDisabled={(option) => option?.is_active !== undefined && option?.is_active === 0}
                            value={this.props?.value ? this.props?.value : null}
                            getOptionLabel={(option) => option.text ? option.text : ''}
                            getOptionSelected={(option, value) => option.value === value}
                            filterOptions={filterOptions}
                            popupIcon={(this.props.icon === 'hide') ? '' : <MDBIcon icon="chevron-right" size='sm' />}
                            onChange={this.props?.onChange}
                            inputValue={this.props?.manageInputValue ? this.props?.inputValue : undefined}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    required={this.props?.isMandatory || false}
                                    placeholder={this.props?.placeholder}
                                    onChange={(event) => {
                                        if (this.props.searchText) {
                                          this.props.searchText(event);
                                        }
                                    }}
                                />
                            }
                        />
                        {error && <div className="errorText">{(this.props.showMandatory && !this.props?.value.value) ? this.state.mandatoryWarning : ""}</div>}
                    </div> :
                <div 
                    className={`${this.props.className ?
                        `SearchSelectFieldContainer ${this.props.className}` :
                        `SearchSelectFieldContainer`} `}
                    style={{width: this.props?.fieldWidth ? this.props?.fieldWidth : ""}}
                >
                    {this.props?.label && <BasicLabel text={this.props?.label || ""} type={"text"}
                        isMandatory={this.props?.isMandatory || false} />}
                    <Autocomplete
                        className={`${error ? "error" : ""}`}
                        id={this.props?.id}
                        disabled={this.props?.disabled ? this.props?.disabled : false}
                        options={this.props?.options ? this.props?.options : []}
                        value={(this.props?.detail_selected ? this.props?.detail_selected : null)}
                        getOptionLabel={(option) => option?.text ? option?.text : ''}
                        getOptionSelected={(option, value) => option.value === value}
                        filterOptions={filterOptions}
                        popupIcon={(this.props.icon === 'hide') ? '' : <MDBIcon icon="chevron-right" size='sm' />}
                        onChange={this.props?.valueSelected}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                variant="outlined"
                                required={this.props?.isMandatory || false}
                                placeholder={this.props?.placeholder}
                                textFieldId={this.props?.textFieldId}
                                onChange={this.props?.searchText}
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    endAdornment: (
                                        <React.Fragment>
                                            {(this.props?.loading) ? <CircularProgress color="inherit" size={15} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    )
                                }}
                            />}
                    />
                    {error && <div className="errorText">{(this.props.showMandatory && this.props?.detail_selected?.text?.length > 0 ? false : true) ? this.state.mandatoryWarning : ""}</div>}
                </div>

            )

        );
    }
}