import React, { Component } from "react";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import BasicTextField from "../../SharedComponents/BasicTextField/BasicTextField";
import SelectField from "../../SharedComponents/SelectField/SelectField";
import DateField from "../../SharedComponents/DateField/DateField";
import * as Constants from "../../../constants/constants";
import clearTrackService from "../../../services/service";
import { filterClearanceConfig, clearenceRecordJSON } from "./Config";
import SearchSelectField from "../../SharedComponents/SearchSelectField";
import { withUserContext } from '../../../contexts/UserContext';
import axios from 'axios';
import { songList } from "../../Navigation/CostAllocationCaluculator/Config";
import { BatchOptions } from "../../Navigation/Reports/config";

export default withUserContext(class ClearanceRecords extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterList: {
        showList: [],
        songsList: [],
        seasonList: [],
        clearFilters: this.props?.clearFilters,
        termsList: [],
        territoryList: [],
        rightsList: [],
        initialRightsList: [],
        altInitialRightsList: [],
        optRightsList: [],
        statusList: [],
        clearanceRepList: [],
        usesList: [],
        performerList: [],
        writerList: [],
        licensorList: [],
        licensorPayeeList: [],
        userList: [],
        showBasedSeasonList: [],
        batchOptions:[...BatchOptions],
      },
      sortBy: "Show/Season",
      loadingPerformer: false,
      isUserListFetching: false,
      isLiceRepListFetching: false,
      clearanceRecordsFilter: this.props?.clearanceRecordsFilter,
      filterSearchList: [],
      loadingShow: false,
      loadingWriter: false,
      loadingLicensor: false,
      loadingPayee: false,
      clearenceRecordJSON: { ...clearenceRecordJSON },
      selectedClearanceRepForSearch: null,
      selectedSongForSearch: null,
      selectedLicenseRepForSearch: null,
      selectedPerformerForSearch: null,
      selectedWriterValue: null,
      searchCancelToken: null,
      isSongFetching: false
    };
  }
  componentDidMount() {
    document.title = 'Clearance Records'
    if (this.props?.userContext?.active_tenant?.tenant_id) {
      this.usesList("lu_music_uses", "usesList");
      this.fetchStaticData("lu_ad_card", "clearanceRepList");
      this.fetchStaticData("lu_status", "statusList");
      this.fetchEntityList("SEASON", filterClearanceConfig.season, "seasonList");
      this.fetchEntityList("TERRITORY", filterClearanceConfig.territory, "territoryList");
      this.fetchEntityList("TERM", filterClearanceConfig.term, "termsList");
      this.fetchEntityList("RIGHTS", filterClearanceConfig.rights, "rightsList");
      this.getShowDetails("", true)
      this.getSongsList("", true)
      // this.getUserList('USER', filterClearanceConfig.user, "userList", null)
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps?.userContext?.active_tenant?.tenant_id != this.props?.userContext?.active_tenant?.tenant_id) {
      this.usesList("lu_music_uses", "usesList");
      this.fetchStaticData("lu_ad_card", "clearanceRepList");
      this.fetchStaticData("lu_status", "statusList");
      this.fetchEntityList("SEASON", filterClearanceConfig.season, "seasonList");
      this.fetchEntityList("TERRITORY", filterClearanceConfig.territory, "territoryList");
      this.fetchEntityList("TERM", filterClearanceConfig.term, "termsList");
      this.fetchEntityList("RIGHTS", filterClearanceConfig.rights, "rightsList");
      this.getShowDetails("", true)
      this.getSongsList("", true)
      // this.getUserList('USER', filterClearanceConfig.user, "userList", null)
    }
    if (this.props?.clearFilters != this.state.clearFilters) {
      let mockClear = this.props?.clearFilters
      this.setState({ clearFilters: mockClear, filterSearchList: [] })
    }
    if (this.props?.userContext.dashboardName === "Clearance Records" && this.state.sortBy != this.props?.sortBy) {
      this.setState({ sortBy: this.props?.sortBy })
    }
  }
  usesList = (entity, node) => {
    clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/staticData?staticTable=${entity}`, this.props.userContext?.active_tenant?.tenant_id)
      .then(response => {
        let dataRecord = { ...response.data }
        let formattedList = response.data?.map(item => ({ value: item.id, label: item.name, is_active: item.is_active }));
        formattedList.unshift({ label: "Select", value: null })
        this.updateListState(formattedList, node, 'filterList')
      },
        (err) => {
          console.log("Error in fetching static data:", err)
        })
  }
  fetchStaticData = (entity, node) => {
    clearTrackService
      .getData(
        Constants.ClearTrackServiceBaseUrl +
        `/staticData?staticTable=${entity}`,
        this.props.userContext?.active_tenant?.tenant_id
      )
      .then(
        (response) => {
          let formattedList = response.data?.map((item) => ({
            value: item.id,
            label: item.name,
            is_active: item.is_active,
          }));
          formattedList.unshift({ label: "Select", value: null })
          this.updateListState(formattedList, node, "filterList");
        },
        (err) => {
          console.log("Error in fetching static data:", err);
        }
      );
  };

  fetchEntityList = (entity, config, node) => {
    clearTrackService
      .getData(
        Constants.ClearTrackServiceBaseUrl +
        `/entitySearch?entity=${entity}&searchString=null`,
        this.props.userContext?.active_tenant?.tenant_id
      )
      .then(
        (response) => {
          let formattedList = response.data?.map((item) => ({
            value: item[config.id],
            label: item[config.name],
            is_active: item[config.is_active],
          }));
          formattedList.unshift({ label: "Select", value: null })
          this.updateListState(formattedList, node, "filterList");
          if (entity === "RIGHTS") {
            let initialRights = response.data?.filter(
              (right) => (right?.is_initial_right === 1 || right?.can_be_included_with_init_right === 1)
            );
            let altinitialRights = response.data?.filter(
              (right) => (right?.can_be_alt_init_right === 1)
            );
            let optRights = response.data?.filter(
              (right) => (right?.is_optional === 1)
            );
            let inItList = initialRights?.map((item) => ({
              value: item[config.id],
              label: item[config.name],
            }));
            let altinItList = altinitialRights?.map((item) => ({
              value: item[config.id],
              label: item[config.name],
            }));
            let opttList = optRights?.map((item) => ({
              value: item[config.id],
              label: item[config.name],
            }));
            inItList.unshift({ label: "Select", value: null })
            altinItList.unshift({ label: "Select", value: null })
            opttList.unshift({ label: "Select", value: null })
            this.updateListState(inItList, "initialRightsList", "filterList");
            this.updateListState(altinItList, "altInitialRightsList", "filterList");
            this.updateListState(opttList, "optRightsList", "filterList");
          }
        },
        (err) => {
          console.log("Error in fetching entity list:", err);
        }
      );
  };
  getPerformerDetails = (e) => {
    if (e.target.value !== null) {
      let option = {
        "searchString": e.target.value
      }
      if (this.state.searchCancelToken != null)
        this.state.searchCancelToken.cancel("Operation canceled due to new request");
      var cancelToken = axios.CancelToken.source();
      this.setState({ searchCancelToken: cancelToken, loadingPerformer: true });
      clearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${"PERFORMER"}&searchString=${e.target.value}`, cancelToken, this.props.userContext?.active_tenant?.tenant_id)
        .then((response) => {
          let formattedList = response.data?.map((item) => ({
            value: item.performer_id,
            text: item.performer_name
            //text: item.Code === "COM" ? `${item.Name} (Composer)` : item.Name,
            //selectedCode: item.Code === "COM" ? `com_${item.Id}` : item.Name,
          }));
          formattedList.unshift({ label: "Select", value: null })
          this.updateListState(formattedList, "performerList", "filterList");
          this.setState({ loadingPerformer: false });
        })
        .catch((err) => {
          console.log("error", err);
          this.setState({ loadingPerformer: false });
        });
    }
  };
  getSongsList = (e, mount = false) => {
    if (this.state.searchCancelToken != null)
      this.state.searchCancelToken.cancel("Operation canceled due to new request");
    var cancelToken = axios.CancelToken.source();
    this.setState({ searchCancelToken: cancelToken, isSongFetching: true });
    clearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl +
      `/entitySearch?entity=${"SONGS"}&searchString=${mount ? e : e.target.value}`,
      cancelToken, this.props.userContext?.active_tenant?.tenant_id)
      .then((response) => {
        let SongDataList = response.data?.map((item) => ({
          value: item.song_id,
          text: item.song_title,
          //  selectedSong: item.song_id
        }));
        SongDataList.unshift({ label: "Select", value: null })
        this.updateListState(SongDataList, "songsList", "filterList");
        this.setState({ isSongFetching: false })

      })

      .catch((err) => {
        this.setState({ isSongFetching: false })
        console.log("songlist", songList)
      });
  }

  getShowDetails = (e, mount = false) => {
    if (this.state.searchCancelToken != null)
      this.state.searchCancelToken.cancel("Operation canceled due to new request");
    var cancelToken = axios.CancelToken.source();
    this.setState({ searchCancelToken: cancelToken });
    if (mount == false && e?.target?.value !== null || mount == true && e !== null) {
      this.setState({ loadingShow: true });
      clearTrackService
        .getDataWithCancel(
          Constants.ClearTrackServiceBaseUrl +
          `/entitySearch?entity=${"SHOW"}&searchString=${mount ? e : e.target.value
          }`,
          cancelToken,
          this.props.userContext?.active_tenant?.tenant_id
        )
        .then((response) => {
          let formattedList = response.data?.map((item) => ({
            value: item.show_id,
            text: item.show_name,
            selectedCode: item.show_id,
            division_id: item?.division_id
          }));
          formattedList.unshift({ label: "Select", value: null })
          this.updateListState(formattedList, "showList", "filterList");
          this.setState({ loadingShow: false });
        })
        .catch((err) => {
          console.log("error", err);
          this.setState({ loadingShow: false });
        });
    }
  };

  getShowBasedEpisodes = (showSeasonId) => {
    this.setState({ isFetchingEpisodes: true });
    clearTrackService
      .getData(
        Constants.ClearTrackServiceBaseUrl +
        `/showSeasonEpisodes?show_season_id=${showSeasonId}`,
        this.props.userContext?.active_tenant?.tenant_id,
        1
      )
      .then(
        (response) => {
          let formattedList = response.data?.map((item) => ({
            value: item.show_season_episode_id,
            label: `${item.episode_number ? item.episode_number : ''}${item.episode_title ? '-' + item.episode_title : ''}`,
          }));
           formattedList.unshift({ label: "Select", value: null })
           if (typeof (selectedShow) !== 'undefined') {
            this.updateListState(formattedList, "seasonBasedEpisodeList", 'filterList')
          } else {
            this.updateListState(formattedList.length > 0 ? formattedList : [], 'seasonBasedEpisodeList', 'filterList')
          }
         },
        (err) => {
          console.log("Error in fetching Details:", err);
        }
      );
  };

  selectedShowBasedSeason = (newValue) => {
    this.props.updateListState(
      null,
      "selectedSeason",
      "clearanceRecordsFilter"
    );
    this.handleFilterListSelection(
      "season",
      null,
      null,
      "selectedSeason"
    );
    clearTrackService
      .getData(
        Constants.ClearTrackServiceBaseUrl +
        `/seasonsBasedOnShows?show_id=${newValue.value}`,
        this.props?.userContext?.active_tenant?.tenant_id
      ).then(
        (response) => {
          let selectedShow = response?.data[0]?.seasons?.find(
            (item) => response?.data[0]?.show_id === newValue.value
          )?.show_season_id;
          let selectedShowSeasons = response?.data[0]?.seasons?.length > 0 ? response?.data[0]?.seasons?.filter(
            (item, index, data) =>
              index === data.findIndex((t) => t.season_name === item.season_name && item.show_season_id !== null)
          ) : [];
          let formattedList = selectedShowSeasons?.length > 0 ? selectedShowSeasons?.map((item) => ({
            value: item.season_id,
            label: item.season_name,
          })) : [];
          let seasonBasedEpisodes = response?.data[0]?.seasons;
          this.setState({ seasonBasedEpisodes })
          formattedList.unshift({ label: "Select", value: null })
          if (typeof (selectedShow) !== 'undefined') {
            this.updateListState(formattedList, "showBasedSeasonList", 'filterList')
          } else {
            this.updateListState(formattedList.length > 0 ? formattedList : [], 'showBasedSeasonList', 'filterList')
          }
        })
      .catch(error => {
        console.log("error--", error)
      })
  }

  getWritersDetails = (e) => {
    if (e.target.value !== null) {
      let option = {
        "searchString": e.target.value,
      }
      if (this.state.searchCancelToken != null)
        this.state.searchCancelToken.cancel("Operation canceled due to new request");
      var cancelToken = axios.CancelToken.source();
      this.setState({ searchCancelToken: cancelToken, loadingWriter: true });
      clearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${"WRITER"}&searchString=${e.target.value}`, cancelToken, this.props.userContext?.active_tenant?.tenant_id)
        .then((response) => {
          let formattedList = response.data?.map((item) => ({
            value: item.writer_id,
            text: item.writer_name,
            selectedCode: item.writer_id
          }));
          formattedList.unshift({ label: "Select", value: null })
          this.updateListState(formattedList, "writerList", "filterList");
          this.setState({ loadingWriter: false });
        })
        .catch((err) => {
          console.log("error", err);
          this.setState({ loadingWriter: false });
        });
    }
  };

  getLicensorDetails = (e) => {
    if (this.state.searchCancelToken != null)
      this.state.searchCancelToken.cancel("Operation canceled due to new request");
    var cancelToken = axios.CancelToken.source();
    this.setState({ searchCancelToken: cancelToken });
    if (e.target.value !== null) {
      this.setState({ loadingLicensor: true });
      clearTrackService
        .getDataWithCancel(
          Constants.ClearTrackServiceBaseUrl +
          `/entitySearch?entity=${"LICENSOR"}&searchString=${e.target.value
          }`, cancelToken,
          this.props.userContext?.active_tenant?.tenant_id
        )
        .then((response) => {
          let formattedList = response.data?.map((item) => ({
            value: item.licensor_id,
            text: item.licensor_name,
            selectedCode: item.licensor_id
          }));
          formattedList.unshift({ label: "Select", value: null })
          this.updateListState(formattedList, "licensorList", "filterList");
          this.setState({ loadingLicensor: false });
        })
        .catch((err) => {
          console.log("error", err);
          this.setState({ loadingLicensor: false });
        });
    }
  };

  getLicensorPayeeDetails = (e) => {
    if (this.state.searchCancelToken != null)
      this.state.searchCancelToken.cancel("Operation canceled due to new request");
    var cancelToken = axios.CancelToken.source();
    this.setState({ searchCancelToken: cancelToken });
    if (e.target.value !== null) {
      this.setState({ loadingPayee: true });
      clearTrackService
        .getDataWithCancel(
          Constants.ClearTrackServiceBaseUrl +
          `/entitySearch?entity=${"PAYEE"}&searchString=${e.target.value
          }`,
          cancelToken,
          this.props.userContext?.active_tenant?.tenant_id
        )
        .then((response) => {
          let formattedList = response.data?.map((item) => ({
            value: item.payee_id,
            text: item.payee_company,
            selectedCode: item.payee_id
          }));
          formattedList.unshift({ label: "Select", value: null })
          this.updateListState(formattedList, "licensorPayeeList", "filterList");
          this.setState({ loadingPayee: false });
        })
        .catch((err) => {
          console.log("error", err);
          this.setState({ loadingPayee: false });
        });
    }
  };

  getUserList = (entity, config, node, searchString) => {
    if (this.state.searchCancelToken != null)
      this.state.searchCancelToken.cancel("Operation canceled due to new request");
    var cancelToken = axios.CancelToken.source();
    this.setState({ searchCancelToken: cancelToken });
    clearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entity}&searchString=${searchString}`, cancelToken, this.props.userContext?.active_tenant?.tenant_id)
      .then(response => {
        let formattedList = response.data?.map((item) => ({
          value: item[config.id],
          text: item[config.name]
        }));
        this.updateListState(formattedList, node, "filterList");
        this.setState({ isUserListFetching: false, isLiceRepListFetching: false })
      },
        (err) => {
          console.log("Error in fetching license data:", err)
        })

  }

  updateListState = (value, node, list) => {
    this.setState((prevState) => ({
      [list]: {
        ...prevState[list],
        [node]: value,
      },
    }));
  };
  handleFilterListSelection = (type, val, name, node) => {
    this.updateFilteredClearanceData(type, val, name, node)
  }
  updateFilteredClearanceData = (type, val, name, node) => {
    let filtereredClearance = [...this.state.filterSearchList]
    let newFilterValue = {
      type: type,
      label: name,
      value: val,
      node: node
    }
    let check = filtereredClearance.filter((item, index) => {
      if (item.type === type) {
        return filtereredClearance[index] = newFilterValue

      }
    })
    if (check.length === 0) {
      filtereredClearance.push(newFilterValue)
    }
    this.setState({ filterSearchList: filtereredClearance })
    let selectedClearenceFilters = {
      main_clearance_id_search_text: filtereredClearance.find((item) => item.type === "id")?.label || null,
      type_search_ids: filtereredClearance.find((item) => item.type === "type")?.label || null,
      showtype_search_id:filtereredClearance.find((item)=>item.type === "showtype")?.label || null,
      show_year_search_text: filtereredClearance.find((item) => item.type === "showYr")?.label || null,
      show_search_ids: filtereredClearance.find((item) => item.type === "show")?.value || null,
      season_search_ids: filtereredClearance.find((item) => item.type === "season")?.value || null,
      episode_title_search_text: filtereredClearance.find((item) => item.type === "episode name")?.value || null,
      episode_no_search_text: filtereredClearance.find((item) => item.type === "episode")?.label || null,
      performer_search_ids: filtereredClearance.find((item) => item.type === "performer")?.value || null,
      //composer_search_ids:(filtereredClearance.find((item) => item.type === "performer")?.label.includes("com") ? filtereredClearance.find((item) => item.type === "performer")?.label : null) || null,
      air_date_search_text: filtereredClearance.find((item) => item.type === "airDate")?.label || null,
      scene_search_text: filtereredClearance.find((item) => item.type === "scene")?.label || null,
      record_options_search_ids: filtereredClearance.find((item) => item.type === "CLClearanceRep")?.value || null,
      territory_search_ids: filtereredClearance.find((item) => item.type === "territory")?.value || null,
      status_search_ids: filtereredClearance.find((item) => item.type === "status")?.value || null,
      clearance_rep_search_ids: filtereredClearance.find((item) => item.type === "user")?.value || null,
      licensee_rep_search_ids: filtereredClearance.find((item) => item.type === "licensorUser")?.value || null,
      use_search_ids: filtereredClearance.find((item) => item.type === "uses")?.value || null,
      song_search_text: filtereredClearance.find((item) => item.type === "selectedSong")?.label || null,
      writer_search_ids: filtereredClearance.find((item) => item.type === "writer")?.value || null,
      duration_search_text: filtereredClearance.find((item) => item.type === "duration")?.label || null,
      init_right_search_ids: filtereredClearance.find((item) => item.type === "initial right")?.value || null,
      init_term_search_ids: filtereredClearance.find((item) => item.type === "initial-right term")?.value || null,
      init_territory_search_ids: filtereredClearance.find((item) => item.type === "initial-right territory")?.value || null,
      alt_init_right_search_ids:
        filtereredClearance.find((item) => item.type === "alt-initial right")?.value || null,
      alt_init_territory_search_ids: filtereredClearance.find((item) => item.type === "alt-right territory")?.value || null,
      option_right_search_ids: filtereredClearance.find((item) => item.type === "optional rights")?.value || null,
      option_term_search_ids: filtereredClearance.find((item) => item.type === "optional term")?.value || null,
      option_territory_search_ids: filtereredClearance.find((item) => item.type === "optional territory")?.value || null,
      licensor_search_ids: filtereredClearance.find((item) => item.type === "licensorName")?.value || null,
      licensor_type_search_ids: filtereredClearance.find((item) => item.type === "SMLicensor")?.label || null,
      licensor_status_search_ids: filtereredClearance.find((item) => item.type === "licensor status")?.value || null,
      is_provides_own_lic_flag: filtereredClearance.find((item) => item.type === "providesOwnLic")?.label || null,
      is_active_flag: filtereredClearance.find((item) => item.type === "activeInactive")?.label || null,
      reliance_ltr_rec_flag: filtereredClearance.find((item) => item.type === "relianceLtrRcvr")?.label || null,
      int_lic_w_change_flag: filtereredClearance.find((item) => item.type === "intLicChanges")?.label || null,
      payee_search_ids: filtereredClearance.find((item) => item.type === "licensorPayee")?.value || null,
      quote_date_search_text: filtereredClearance.find((item) => item.type === "quoteReqDate")?.label || null,
      lic_date_search_text: filtereredClearance.find((item) => item.type === "licReqDate")?.label || null,
      confirm_date_search_text: filtereredClearance.find((item) => item.type === "confirm_date_search_text")?.label || null,
      check_search_text: filtereredClearance.find((item) => item.type === "checkNo")?.label || null,
      note_paid_flag: filtereredClearance.find((item) => item.type === "notePaid")?.label || null,
      confirm_date_search_text: filtereredClearance.find((item) => item.type === "confirmDate")?.label || null,
      in_batch: filtereredClearance.find((item) => item.type === "inBatch")?.label || null,
      order_by: null,
      order_by_clause: this.state.sortBy,
      page_count: this.props?.rowsPerPage,
      page_no: this.props?.page > 0 ? this.props?.page : 1,
    };
    this.props.updateFilteredList(
      filtereredClearance,
      selectedClearenceFilters,
      type
    );
  }



  render() {
    let { clearanceRecordsFilter } = this.props;
    return (
      <div className="filterFieldsContainer">
        <MDBRow>
          <MDBCol md={5}>
            <MDBRow>
              <MDBCol md={12}>
                <h6>Details</h6>
              </MDBCol>
              <MDBCol md={3}>
                <BasicTextField
                  id="id"
                  value={clearanceRecordsFilter?.id || ''}
                  placeholder="- Id -"
                  onKeyUp={(e) =>{
                    if(e.keyCode === 13){
                        this.props?.handleFilterSearch()
                    }
                  }}
                  onChange={(e) => {
                    this.handleFilterListSelection(
                      "id",
                      "id",
                      e.target.value,
                      "id"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "id",
                      "clearanceRecordsFilter"
                    );
                  }}
                />
              </MDBCol>
              <MDBCol md={3}>
                <SelectField
                  id="Type"
                  value={clearanceRecordsFilter?.selectedType || null}
                  placeHolderText="- Type -"
                  options={[{ label: "Select", value: null }, { label: "Main Title CR’s", value: "1" }, { label: "Clearance Records", value: "0" }]}
                  onChange={(e) => {
                    this.handleFilterListSelection(
                      "type",
                      "type",
                      e.target.value,
                      "selectedType"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "selectedType",
                      "clearanceRecordsFilter"
                    );
                  }}
                />
              </MDBCol>
              <MDBCol md={3}>
                <SelectField
                  id="show type"
                  value={clearanceRecordsFilter?.selectedShowType || null}
                  placeHolderText="- Show Type -"
                  options={[{ label: "Select", value: null }, { label: "WB TV", value: "1" }, { label: "Animation", value: "2" }]}
                  onChange={(e) => {
                    this.handleFilterListSelection(
                      "showtype",
                      "showtype",
                      e.target.value,
                      "selectedShowType"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "selectedShowType",
                      "clearanceRecordsFilter"
                    );
                  }}
                />
              </MDBCol>
              <MDBCol md={6}>
                {/* <SelectField
                  id="Territory"
                  value={clearanceRecordsFilter?.selectedTerritory || ""}
                  placeHolderText="- Territory -"
                  options={
                    this.state.filterList?.territoryList || []}
                  onChange={(e) => {
                    let selectedValue =
                      this.state.filterList?.territoryList?.find(
                        (item) => item.value === e.target.value
                      );
                    this.handleFilterListSelection(
                      "territory",
                      selectedValue?.value,
                      selectedValue?.label,
                      "selectedTerritory"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "selectedTerritory",
                      "clearanceRecordsFilter"
                    );
                  }}
                /> */}
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt2">
              <MDBCol md={6} className="lh-1">
                <SearchSelectField
                  id={"key27"}
                  detail_selected={this.props?.clearanceRecordsFilter?.selectedShow ? clearanceRecordsFilter?.selectedShow : null}
                  options={this.state.filterList?.showList || []}
                  loading={this.state.loadingShow}
                  width="80%"
                  multiple={false}
                  searchSelect={true}
                  searchText={this.getShowDetails}
                  placeholder="- show -"
                  valueSelected={(e, newValue) => {
                    if (newValue) {
                      this.setState((prevState) => ({
                        clearanceRecordsFilter: {
                          ...prevState.clearanceRecordsFilter,
                          ["selectedShow"]: newValue,
                        },
                      }));
                      this.selectedShowBasedSeason(newValue)
                      this.props.updateListState(
                        newValue,
                        "selectedShow",
                        "clearanceRecordsFilter"
                      );
                      this.handleFilterListSelection(
                        "show",
                        newValue?.value,
                        newValue?.selectedCode,
                        "selectedShow"
                      );
                    } else {
                      this.setState({ clearanceRecordsFilter: { ...this.state.clearanceRecordsFilter, selectedShow: null } }, () => {
                        if (!this.state.clearanceRecordsFilter?.selectedShow) {
                          this.props.updateListState(
                            null,
                            "selectedSeason",
                            "clearanceRecordsFilter"
                          );
                          this.handleFilterListSelection(
                            "season",
                            null,
                            null,
                            "selectedSeason"
                          );
                          this.props.updateListState(
                            null,
                            "selectedEpisode",
                            "clearanceRecordsFilter"
                          );
                          this.handleFilterListSelection(
                            "episode name",
                            null,
                            null,
                            "selectedEpisode"
                          );
                        }
                      })
                      this.props.updateListState(
                        null,
                        "selectedShow",
                        "clearanceRecordsFilter"
                      );
                      this.handleFilterListSelection(
                        "show",
                        null,
                        null,
                        "selectedShow"
                      );
                    }
                  }}
                />
              </MDBCol>
              <MDBCol md={6} className="lh-1">
                <SelectField
                  value={clearanceRecordsFilter?.selectedStatus || ""}
                  options={this.state.filterList?.statusList || []}
                  placeHolderText="- Status -"
                  onChange={(e) => {
                    let selectedValue = this.state.filterList?.statusList?.find(
                      (item) => item.value === e.target.value
                    );
                    this.handleFilterListSelection(
                      "status",
                      selectedValue?.value,
                      selectedValue?.label,
                      "selectedStatus"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "selectedStatus",
                      "clearanceRecordsFilter"
                    );
                  }}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt2">
              <MDBCol md={3} className="lh-1">
                <SelectField
                  id="season"
                  value={clearanceRecordsFilter?.selectedSeason || ""}
                  options={clearanceRecordsFilter?.selectedShow ? this.state.filterList?.showBasedSeasonList : this.state.filterList?.seasonList || []}
                  placeHolderText="- Season -"
                  onChange={(e) => {
                    let selectedValue = (clearanceRecordsFilter?.selectedShow ? this.state.filterList?.showBasedSeasonList : this.state.filterList?.seasonList)?.find(
                      (item) => item.value === e.target.value
                    );
                    this.props.updateListState(
                      e.target.value,
                      "selectedSeason",
                      "clearanceRecordsFilter"
                    );
                    this.handleFilterListSelection(
                      "season",
                      selectedValue?.value,
                      selectedValue?.label,
                      "selectedSeason"
                    );
                    let selectedShowSeason =
                      this.state.seasonBasedEpisodes?.find(
                        (item) => item.season_id === e.target.value
                      )?.show_season_id;
                    this.setState(
                      { show_season_id: selectedShowSeason },
                      () => {
                        this.getShowBasedEpisodes(this.state.show_season_id);
                      }
                    );
                  }}
                />
              </MDBCol>
              <MDBCol md={3} className="lh-1">
                <BasicTextField
                  id="showYr"
                  value={clearanceRecordsFilter?.showYr || ''}
                  placeholder="- Show Yr. -"
                  onChange={(e) => {
                    this.handleFilterListSelection(
                      "showYr",
                      "showYr",
                      e.target.value,
                      "showYr"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "showYr",
                      "clearanceRecordsFilter"
                    );
                  }}
                  onKeyUp={(e) =>{
                    if(e.keyCode === 13){
                        this.props?.handleFilterSearch()
                    }
                }}
                />
              </MDBCol>
              <MDBCol md={6}>
                <SearchSelectField
                  id="clearence rep"
                  placeholder="- Clearance Rep -"
                  width="100%"
                  searchSelect={true}
                  multiple={false}
                  // detail_selected={this.props?.clearanceRecordsFilter?.user ? {
                  //   value: this.props?.clearanceRecordsFilter?.user,
                  //   text: this.state.filterList?.userList.find(item => item.value === this.props?.clearanceRecordsFilter?.user)?.text
                  // } : null}
                  detail_selected={this.props?.clearanceRecordsFilter?.user ? this.state.selectedClearanceRepForSearch : null}
                  loading={this.state.isUserListFetching}
                  options={this.state.filterList?.userList || []}
                  valueSelected={(e, newValue) => {
                    let selectedShow = this.state.filterList?.userList?.find(item => item.value === (newValue != null ? newValue.value : null));
                    this.props.updateListState(newValue != null ? newValue.value : null, 'user', 'clearanceRecordsFilter')
                    this.setState({ selectedClearanceRepForSearch: newValue })
                    this.handleFilterListSelection("user", (newValue != null ? selectedShow?.value : null), newValue != null ? selectedShow?.text : null, "user")
                  }}
                  searchText={ev => {
                    if (ev.target.value !== "" && ev.target.value !== null) {
                      this.setState({ isUserListFetching: true })
                      this.getUserList("USER", filterClearanceConfig.user, "userList", ev.target.value);
                    } else {
                      this.setState({ isUserListFetching: false })
                    }
                  }}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              {/* <MDBCol md={6} className="lh-1"> */}
              <MDBCol md={6} className="lh-1">
              <SelectField
                    id="episode"
                    // label={"Seasons"}
                    value={
                      clearanceRecordsFilter?.selectedEpisode || ''
                    }
                    placeHolderText="- Episode Name -"
                    options={clearanceRecordsFilter?.selectedSeason ? this.state.filterList?.seasonBasedEpisodeList : this.state.filterList?.episodeList || []}
                    width="80%"
                    multiple={false}
                    onChange={(e) => {
                      let selectedValue = (clearanceRecordsFilter?.selectedSeason ? this.state.filterList?.seasonBasedEpisodeList : this.state.filterList?.episodeList)?.find(
                        (item) => item.value === e.target.value
                      );
                      this.props.updateListState(
                        e.target.value,
                        "selectedEpisode",
                        "clearanceRecordsFilter"
                      );
                      this.handleFilterListSelection(
                        "episode name",
                        selectedValue?.value,
                        selectedValue?.label,
                        "selectedEpisode"
                      );
                    }}
                  />
                {/* </MDBCol>
                  <BasicTextField
                    id="episodeName"
                    value={clearanceRecordsFilter?.episodeName || ''}
                    placeholder="- Episode Name -"
                    onChange={(e) => {
                      this.handleFilterListSelection(
                        "episode name",
                        "episodeName",
                        e.target.value,
                        "episodeName"
                      );
                      this.props.updateListState(
                        e.target.value,
                        "episodeName",
                        "clearanceRecordsFilter"
                      );
                    }}
                  /> */}
              </MDBCol>
              <MDBCol md={6}>
                <SearchSelectField
                  id="License rep"
                  placeholder="- License Rep -"
                  width="100%"
                  searchSelect={true}
                  multiple={false}
                  // detail_selected={this.props?.clearanceRecordsFilter?.licensorUser ? {
                  //   value: this.props?.clearanceRecordsFilter?.licensorUser,
                  //   text: this.state.filterList?.userList.find(item => item.value === this.props?.clearanceRecordsFilter?.licensorUser)?.text
                  // } : null}
                  detail_selected={this.props?.clearanceRecordsFilter?.licensorUser ? this.state.selectedLicenseRepForSearch : null}
                  options={this.state.filterList?.userList || []}
                  valueSelected={(e, newValue) => {
                    let selectedShow = this.state.filterList?.userList?.find(item => item.value === (newValue != null ? newValue.value : null));
                    this.props.updateListState(newValue != null ? newValue.value : null, 'licensorUser', 'clearanceRecordsFilter')
                    this.setState({ selectedLicenseRepForSearch: newValue })
                    this.handleFilterListSelection("licensorUser", (newValue != null ? selectedShow?.value : null), newValue != null ? selectedShow?.text : null, "licensorUser")
                  }}
                  searchText={ev => {
                    if (ev.target.value !== "" && ev.target.value !== null) {
                      this.setState({ isLiceRepListFetching: true })
                      this.getUserList("USER", filterClearanceConfig.user, "userList", ev.target.value);
                    } else {
                      this.setState({ isLiceRepListFetching: false })
                    }
                  }}

                />

              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md={3} className="lh-1">
                <BasicTextField
                  id="episode"
                  value={clearanceRecordsFilter?.episode || ''}
                  placeholder="- Episode # -"
                  onChange={(e) => {
                    this.handleFilterListSelection(
                      "episode",
                      "episode",
                      e.target.value,
                      "episode"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "episode",
                      "clearanceRecordsFilter"
                    );
                  }}
                  onKeyUp={(e) =>{
                    if(e.keyCode === 13){
                        this.props?.handleFilterSearch()
                    }
                }}
                />
              </MDBCol>
              <MDBCol md={3}>
                <DateField
                  id="Air Date"
                  onHoverDateText="- Air Date -"
                  placeholder="- Air Date -"
                  value={clearanceRecordsFilter?.airDate || null}
                  onChange={(e) => {
                    this.handleFilterListSelection(
                      "airDate",
                      "airDate",
                      e.target.value,
                      "airDate"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "airDate",
                      "clearanceRecordsFilter"
                    );
                  }}
                />
              </MDBCol>
              <MDBCol md={6} className="lh-1">
                <SelectField
                  id="use"
                  value={clearanceRecordsFilter?.selectedUses || ""}
                  options={this.state.filterList?.usesList || []}
                  placeHolderText="- Uses -"
                  onChange={(e) => {
                    let selectedValue = this.state.filterList?.usesList?.find(
                      (item) => item.value === e.target.value
                    );
                    this.handleFilterListSelection(
                      "uses",
                      selectedValue?.value,
                      selectedValue?.label,
                      "selectedUses"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "selectedUses",
                      "clearanceRecordsFilter"
                    );
                  }}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md={12} className="lh-1">
                <BasicTextField
                  id="scene"
                  value={clearanceRecordsFilter?.scene || ''}
                  placeholder="- Scene -"
                  onChange={(e) => {
                    this.handleFilterListSelection(
                      "scene",
                      "scene",
                      e.target.value,
                      "scene"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "scene",
                      "clearanceRecordsFilter"
                    );
                  }}
                  onKeyUp={(e) =>{
                    if(e.keyCode === 13){
                        this.props?.handleFilterSearch()
                    }
                }}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md={6}>
                <SelectField
                  id="clRecordsOptions"
                  value={clearanceRecordsFilter?.selectedCLRecord || ""}
                  options={this.state.filterList?.clearanceRepList || []}
                  placeHolderText="- Cl.Record Options -"
                  onChange={(e) => {
                    let selectedValue =
                      this.state.filterList?.clearanceRepList?.find(
                        (item) => item.value === e.target.value
                      );
                    this.handleFilterListSelection(
                      "CLClearanceRep",
                      selectedValue?.value,
                      selectedValue?.label,
                      "selectedCLRecord"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "selectedCLRecord",
                      "clearanceRecordsFilter"
                    );
                  }}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md={12}>
                <h6>Song</h6>
              </MDBCol>
              <MDBCol md={6}>
              <BasicTextField
                  placeholder={'- Title -'}
                  value={clearanceRecordsFilter?.selectedSong || ''}
                  onChange={(e) => {
                    this.handleFilterListSelection(
                      "selectedSong",
                      "selectedSong",
                      e.target.value,
                      "selectedSong"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "selectedSong",
                      "clearanceRecordsFilter"
                    );
                  }}
                  onKeyUp={(e) =>{
                    if(e.keyCode === 13){
                        this.props?.handleFilterSearch()
                    }
                  }} />
                {/* <SearchSelectField
                  id={"titleSong"}
                  detail_selected={this.props?.clearanceRecordsFilter?.selectedSong ? this.state.selectedSongForSearch : null}
                  options={this.state.filterList?.songsList || []}
                  loading={this.state.isSongFetching}
                  multiple={false}
                  searchText={this.getSongsList}
                  placeholder="- Title -"
                  searchSelect={true}
                  valueSelected={(e, newValue) => {
                    this.setState({
                      selectedSongForSearch: newValue
                    });
                    this.handleFilterListSelection(
                      "songsList",
                      newValue?.value,
                      newValue?.text,
                      "selectedSongForSearch"
                    );
                    this.props.updateListState(
                      newValue?.value,
                      "selectedSong",
                      "clearanceRecordsFilter"
                    );
                  }}
                /> */}
                
              </MDBCol>

              <MDBCol md={6}>
                <SearchSelectField
                  id={"key27"}
                  detail_selected={this.props?.clearanceRecordsFilter?.selectedPerformer ? this.state.selectedPerformerForSearch : null}
                  options={this.state.filterList?.performerList || []}
                  loading={this.state.loadingPerformer}
                  width="80%"
                  multiple={false}
                  searchSelect={true}
                  searchText={this.getPerformerDetails}
                  placeholder="- Performer -"
                  valueSelected={(e, newValue) => {
                    this.setState({
                      selectedPerformerForSearch: newValue
                    });
                    this.handleFilterListSelection(
                      "performer",
                      newValue?.value,
                      newValue?.text,
                      "selectedPerformer"
                    );
                    this.props.updateListState(
                      newValue?.value,
                      "selectedPerformer",
                      "clearanceRecordsFilter"
                    );
                  }}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow >
              <MDBCol md={6} className="mt1">
                <SearchSelectField
                  id={"key27"}
                  detail_selected={this.props?.clearanceRecordsFilter?.writer ? this.state.selectedWriterValue : null}
                  options={this.state.filterList?.writerList || []}
                  loading={this.state.loadingWriter}
                  width="100%"
                  multiple={false}
                  searchSelect={true}
                  searchText={this.getWritersDetails}
                  placeholder="- Writer -"
                  valueSelected={(e, newValue) => {
                    this.setState({
                      selectedWriterValue: newValue
                    });
                    this.handleFilterListSelection(
                      "writer",
                      newValue?.value,
                      newValue?.text,
                      "writer"
                    );
                    this.props.updateListState(
                      newValue?.value,
                      "writer",
                      "clearanceRecordsFilter"
                    );
                  }}

                />
              </MDBCol>
              <MDBCol md={2} lg={2} xl={2}>
                <span class="labelFont">Duration</span>
              </MDBCol>
              <MDBCol md={4} className="lh-1">
                <BasicTextField
                  placeholder={"__ __"}
                  value={clearanceRecordsFilter?.duration || ''}
                  onChange={(e) => {
                    this.handleFilterListSelection(
                      "duration",
                      "duration",
                      e.target.value,
                      "duration"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "duration",
                      "clearanceRecordsFilter"
                    );
                  }}
                  onKeyUp={(e) =>{
                    if(e.keyCode === 13){
                        this.props?.handleFilterSearch()
                    }
                  }} />
                {/* <TimePicker
                  className={"timePicker"}
                  value={clearanceRecordsFilter?.duration || null}
                  showHour={false}
                  format={'hh:mm a'}
                  use12Hours
                  onChange={(e) => {
                    this.handleFilterListSelection(
                      "duration",
                      "duration",
                      e,
                      "duration"
                    );
                    this.props.updateListState(
                      e,
                      "duration",
                      "clearanceRecordsFilter"
                    );
                  }}
                /> */}
              </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol md={2}></MDBCol>
          <MDBCol md={5}>
            <MDBRow>
              <MDBCol md={12}>
                <h6>Rights</h6>
              </MDBCol>
              <MDBCol md={6}>
                <SelectField
                  id="initialRights"
                  value={clearanceRecordsFilter?.selectedInitialRight || ""}
                  placeHolderText="- Initial Rights -"
                  options={
                    this.state.filterList?.initialRightsList || []}
                  onChange={(e) => {
                    let selectedValue =
                      this.state.filterList?.initialRightsList?.find(
                        (item) => item.value === e.target.value
                      );
                    this.handleFilterListSelection(
                      "initial right",
                      selectedValue?.value,
                      selectedValue?.label,
                      "selectedInitialRight"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "selectedInitialRight",
                      "clearanceRecordsFilter"
                    );
                  }}
                />
              </MDBCol>
              <MDBCol md={3}>
                <SelectField
                  id="Term1"
                  options={
                    this.state.filterList?.termsList || []}
                  value={clearanceRecordsFilter?.initialRightsTerm || ""}
                  placeHolderText="- Term -"
                  onChange={(e) => {
                    let selectedValue = this.state.filterList?.termsList?.find(
                      (item) => item.value === e.target.value
                    );
                    this.handleFilterListSelection(
                      "initial-right term",
                      selectedValue?.value,
                      selectedValue?.label,
                      "initialRightsTerm"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "initialRightsTerm",
                      "clearanceRecordsFilter"
                    );
                  }}
                />
              </MDBCol>
              <MDBCol md={3}>
                <SelectField
                  id="Territory2"
                  value={clearanceRecordsFilter?.initialRightsTerritory || ""}
                  placeHolderText="- Territory -"
                  options={
                    this.state.filterList?.territoryList || []}
                  onChange={(e) => {
                    let selectedValue =
                      this.state.filterList?.territoryList?.find(
                        (item) => item.value === e.target.value
                      );
                    this.handleFilterListSelection(
                      "initial-right territory",
                      selectedValue?.value,
                      selectedValue?.label,
                      "initialRightsTerritory"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "initialRightsTerritory",
                      "clearanceRecordsFilter"
                    );
                  }}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md={6}>
                <SelectField
                  id="altRights"
                  value={clearanceRecordsFilter?.selectedAltInitialRight || ""}
                  placeHolderText="- Alt Initial Rights -"
                  options={this.state.filterList?.altInitialRightsList || []}
                  onChange={(e) => {
                    let selectedValue = this.state.filterList?.altInitialRightsList?.find((item) => item.value === e.target.value);
                    this.handleFilterListSelection(
                      "alt-initial right",
                      selectedValue?.value,
                      selectedValue?.label,
                      "selectedAltInitialRight"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "selectedAltInitialRight",
                      "clearanceRecordsFilter"
                    );
                  }}
                />
              </MDBCol>
              <MDBCol md={3}>
                <SelectField
                  id="Term2"
                  value={clearanceRecordsFilter?.altRightsTerm || ""}
                  placeHolderText="- Term -"
                  options={this.state.filterList?.termsList || []}
                  onChange={(e) => {
                    let selectedValue = this.state.filterList?.termsList?.find(
                      (item) => item.value === e.target.value
                    );
                    this.handleFilterListSelection(
                      "alt-right term",
                      selectedValue?.value,
                      selectedValue?.label,
                      "altRightsTerm"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "altRightsTerm",
                      "clearanceRecordsFilter"
                    );
                  }}
                />
              </MDBCol>
              <MDBCol md={3}>
                <SelectField
                  id="Territory4"
                  value={clearanceRecordsFilter?.altRightsTerritory || null}
                  placeHolderText="- Territory -"
                  options={this.state.filterList?.territoryList || []}
                  onChange={(e) => {
                    let selectedValue =
                      this.state.filterList?.territoryList?.find(
                        (item) => item.value === e.target.value
                      );
                    this.handleFilterListSelection(
                      "alt-right territory",
                      selectedValue?.value,
                      selectedValue?.label,
                      "altRightsTerritory"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "altRightsTerritory",
                      "clearanceRecordsFilter"
                    );
                  }}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md={6}>
                <SelectField
                  id="optionalRights"
                  value={clearanceRecordsFilter?.optionalRights || null}
                  options={this.state.filterList?.optRightsList || []}
                  placeHolderText="- optional Rights -"
                  onChange={(e) => {
                    let selectedValue = this.state.filterList?.optRightsList?.find((item) => item.value === e.target.value);
                    this.handleFilterListSelection(
                      "optional rights",
                      selectedValue?.value,
                      selectedValue?.label,
                      "optionalRights"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "optionalRights",
                      "clearanceRecordsFilter"
                    );
                  }}
                />
              </MDBCol>
              <MDBCol md={3}>
                <SelectField
                  id="Term3"
                  value={clearanceRecordsFilter?.optionalTerm || ""}
                  placeHolderText="- Term -"
                  options={this.state.filterList?.termsList || []}
                  onChange={(e) => {
                    let selectedValue = this.state.filterList?.termsList?.find(
                      (item) => item.value === e.target.value
                    );
                    this.handleFilterListSelection(
                      "optional term",
                      selectedValue?.value,
                      selectedValue?.label,
                      "optionalTerm"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "optionalTerm",
                      "clearanceRecordsFilter"
                    );
                  }}
                />
              </MDBCol>
              <MDBCol md={3}>
                <SelectField
                  id="Territory6"
                  value={clearanceRecordsFilter?.optionalTerritory || null}
                  options={this.state.filterList?.territoryList || []}
                  placeHolderText="- Territory -"
                  onChange={(e) => {
                    let selectedValue =
                      this.state.filterList?.territoryList?.find(
                        (item) => item.value === e.target.value
                      );
                    this.handleFilterListSelection(
                      "optional territory",
                      selectedValue?.value,
                      selectedValue?.label,
                      "optionalTerritory"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "optionalTerritory",
                      "clearanceRecordsFilter"
                    );
                  }}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md={12}>
                <h6>License</h6>
              </MDBCol>
              <MDBCol md={6}>
                <SearchSelectField
                  id={"key27"}
                  detail_selected={clearanceRecordsFilter?.licensorName}
                  options={this.state.filterList?.licensorList || []}
                  loading={this.state.loadingLicensor}
                  width="100%"
                  multiple={false}
                  searchSelect={true}
                  searchText={this.getLicensorDetails}
                  placeholder="-Licensor Name -"
                  valueSelected={(e, newValue) => {
                    this.setState((prevState) => ({
                      clearanceRecordsFilter: {
                        ...prevState.clearanceRecordsFilter,
                        ["licensorName"]: newValue,
                      },
                    }));
                    this.handleFilterListSelection(
                      "licensorName",
                      newValue?.value,
                      newValue?.selectedCode,
                      "licensorName"
                    );
                    this.props.updateListState(
                      newValue,
                      "licensorName",
                      "clearanceRecordsFilter"
                    );
                  }}
                />
              </MDBCol>
              <MDBCol md={6}>
                <SearchSelectField
                  id={"key27"}
                  detail_selected={clearanceRecordsFilter?.licensorPayee}
                  options={this.state.filterList?.licensorPayeeList || []}
                  loading={this.state.loadingPayee}
                  width="100%"
                  multiple={false}
                  searchSelect={true}
                  searchText={this.getLicensorPayeeDetails}
                  placeholder="-Payee Name -"
                  valueSelected={(e, newValue) => {
                    this.setState((prevState) => ({
                      clearanceRecordsFilter: {
                        ...prevState.clearanceRecordsFilter,
                        ["licensorPayee"]: newValue,
                      },
                    }));
                    this.handleFilterListSelection(
                      "licensorPayee",
                      newValue?.value,
                      newValue?.selectedCode,
                      "licensorPayee"
                    );
                    this.props.updateListState(
                      newValue,
                      "licensorPayee",
                      "clearanceRecordsFilter"
                    );
                  }}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md={3} className="lh-1">
                <SelectField
                  id="sm"
                  value={clearanceRecordsFilter?.selectedSMLicensor || null}
                  placeHolderText="- S/M -"
                  options={[
                    { label: "Select", value: null },
                    { label: "S", value: "0" },
                    { label: "M", value: "1" },
                    { label: "SM", value: "2" },
                  ]}
                  onChange={(e, newValue) => {
                    this.handleFilterListSelection(
                      "SMLicensor",
                      newValue?.props?.value,
                      newValue?.props?.children,
                      "selectedSMLicensor"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "selectedSMLicensor",
                      "clearanceRecordsFilter"
                    );
                  }}
                />
              </MDBCol>
              <MDBCol md={3} className="lh-1">
                <SelectField
                  id="Licensorstatus"
                  value={clearanceRecordsFilter?.selectedLicensorStatus || null}
                  placeHolderText="- status -"
                  options={this.state.filterList?.statusList || []}
                  onChange={(e) => {
                    let selectedValue = this.state.filterList?.statusList?.find(
                      (item) => item.value === e.target.value
                    );
                    this.handleFilterListSelection(
                      "status",
                      selectedValue?.value,
                      selectedValue?.label,
                      "selectedLicensorStatus"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "selectedLicensorStatus",
                      "clearanceRecordsFilter"
                    );
                  }}
                />
              </MDBCol>
              <MDBCol md={3}>
                <DateField
                  id="quoteReqDate"
                  onHoverDateText="- Quote Req Date -"
                  placeholder="- Quote Req Date -"
                  value={clearanceRecordsFilter?.quoteReqDate || null}
                  onChange={(e) => {
                    this.handleFilterListSelection(
                      "quoteReqDate",
                      "quoteReqDate",
                      e.target.value,
                      "quoteReqDate"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "quoteReqDate",
                      "clearanceRecordsFilter"
                    );
                  }}
                />
              </MDBCol>
              <MDBCol md={3}>
                <DateField
                  id="licReqDate"
                  onHoverDateText="- Lic Req Date -"
                  placeholder="- Lic Req Date -"
                  value={clearanceRecordsFilter?.licReqDate || null}
                  onChange={(e) => {
                    this.handleFilterListSelection(
                      "licReqDate",
                      "licReqDate",
                      e.target.value,
                      "licReqDate"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "licReqDate",
                      "clearanceRecordsFilter"
                    );
                  }}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md={3} className="lh-1">
                <SelectField
                  id="ownLicense"
                  value={clearanceRecordsFilter?.providesOwnLic || null}
                  placeHolderText="- Own License -"
                  options={[{ label: "Select", value: null }, { label: "Yes", value: "1" }, { label: "No", value: "0" }]}
                  onChange={(e) => {
                    this.handleFilterListSelection(
                      "providesOwnLic",
                      "providesOwnLic",
                      e.target.value,
                      "providesOwnLic"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "providesOwnLic",
                      "clearanceRecordsFilter"
                    );
                  }}
                />
              </MDBCol>
              <MDBCol md={3} className="lh-1">
                <SelectField
                  id="activeInactive"
                  value={clearanceRecordsFilter?.activeInactive || null}
                  options={[{ label: "Select", value: null }, { label: "Active", value: "1" }, { label: "Inactive", value: "0" }]}
                  placeHolderText="- Active / Inactive -"
                  onChange={(e) => {
                    this.handleFilterListSelection(
                      "activeInactive",
                      "activeInactive",
                      e.target.value,
                      "activeInactive"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "activeInactive",
                      "clearanceRecordsFilter"
                    );
                  }}
                />
              </MDBCol>
              <MDBCol md={3} className="lh-1">
                <BasicTextField
                  id="checkNo"
                  placeholder="- Check No. -"
                  value={clearanceRecordsFilter?.checkNo || ''}
                  onChange={(e) => {
                    this.handleFilterListSelection(
                      "checkNo",
                      "checkNo",
                      e.target.value,
                      "checkNo"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "checkNo",
                      "clearanceRecordsFilter"
                    );
                  }}
                  onKeyUp={(e) =>{
                    if(e.keyCode === 13){
                        this.props?.handleFilterSearch()
                    }
                  }}
                />
              </MDBCol>
              <MDBCol md={3}>
                <DateField
                  id="ConfirmLtr.Date"
                  onHoverDateText="- Confirm Ltr. Date -"
                  placeholder="- Confirm Ltr. Date -"
                  value={clearanceRecordsFilter?.confirmDate || null}
                  onChange={(e) => {
                    this.handleFilterListSelection(
                      "confirmDate",
                      "confirmDate",
                      e.target.value,
                      "confirmDate"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "confirmDate",
                      "clearanceRecordsFilter"
                    );
                  }}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md={3}>
                <SelectField
                  id="relianceLtr"
                  value={clearanceRecordsFilter?.relianceLtrRcvr || null}
                  placeHolderText="- Reliance Ltr Rcvr -"
                  options={[{ label: "Select", value: null }, { label: "Yes", value: "1" }, { label: "No", value: "0" }]}
                  onChange={(e) => {
                    this.handleFilterListSelection(
                      "relianceLtrRcvr",
                      "relianceLtrRcvr",
                      e.target.value,
                      "relianceLtrRcvr"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "relianceLtrRcvr",
                      "clearanceRecordsFilter"
                    );
                  }}
                />
              </MDBCol>
              <MDBCol md={3}>
                <SelectField
                  id="licChanges"
                  value={clearanceRecordsFilter?.intLicChanges || null}
                  options={[{ label: "Select", value: null }, { label: "Yes", value: "1" }, { label: "No", value: "0" }]}
                  placeHolderText="- Int. Lic w/changes -"
                  onChange={(e) => {
                    this.handleFilterListSelection(
                      "intLicChanges",
                      "intLicChanges",
                      e.target.value,
                      "intLicChanges"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "intLicChanges",
                      "clearanceRecordsFilter"
                    );
                  }}
                />
              </MDBCol>
              <MDBCol md={3}>
                <SelectField
                  id="hasNotePaid"
                  value={clearanceRecordsFilter?.notePaid || null}
                  options={[{ label: "Select", value: null }, { label: "Yes", value: "1" }, { label: "No", value: "0" }]}
                  placeHolderText="- Has Note Paid -"
                  onChange={(e) => {
                    this.handleFilterListSelection(
                      "notePaid",
                      "notePaid",
                      e.target.value,
                      "notePaid"
                    );
                    this.props.updateListState(
                      e.target.value,
                      "notePaid",
                      "clearanceRecordsFilter"
                    );
                  }}
                />
              </MDBCol>
            </MDBRow>
            <MDBCol md={12}>
                <h6>In Batch</h6>
              </MDBCol>
            <MDBCol md={6}>
              <SelectField
                value={clearanceRecordsFilter?.inBatch}
                options={this.state?.filterList?.batchOptions || []}
                placeHolderText="-Batch-"
                onChange={(e) => {
                  this.handleFilterListSelection(
                    "inBatch",
                    "inBatch",
                    e.target.value,
                    "inBatch"
                  );
                  this.props.updateListState(
                    e.target.value,
                    "inBatch",
                    "clearanceRecordsFilter"
                  );
                }

                }
              />
            </MDBCol>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
})
