import React from 'react';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { MDBTooltip } from 'mdb-react-ui-kit';
import { withUserContext } from '../../../contexts/UserContext';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import TablePagination from '@material-ui/core/TablePagination';
import { initialCueSheetConfig, initialCueSheetHeaderConfig, initialStatusConfig, cueSheetMusicEditorConfig } from './Config';
import { handlePaginationList } from '../../../Common/TableHelper';
import MainViewModalComponent from "../../../Common/MainViewModalLayout/Modal/Modal";
import NotificationComponent from '../../../Common/NotificationComponent/NotificationComponent';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import SelectField from '../../SharedComponents/SelectField/SelectField';
import SearchSelectField from '../../SharedComponents/SearchSelectField';
import CueSheetEditDetails from './CueSheetEditDetails';
import CueSheetCopyVersion from './CueSheetCopyVersion';
import * as Constants from '../../../constants/constants';
import ClearTrackService from '../../../services/service';
import CuesheetTableComponet from '../EpisodeAndClearenceDetails/CuesheetTableComponent';
import axios from 'axios';


export default withUserContext(class CuesheetDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cueSheetDetails: JSON.parse(JSON.stringify({ ...initialCueSheetConfig })),
            cueSheetHeaderDetails: JSON.parse(JSON.stringify({ ...initialCueSheetHeaderConfig })),
            isFetchingCueSheetDetails: false,
            renderList: [],
            config: JSON.parse(JSON.stringify({ ...cueSheetMusicEditorConfig })),
            showRecurringModal: false,
            compositions: [],
            isPosting: false,
            showFail: false,
            showSuccess: false,
            page: 0,
            rowsPerPage: 250,
            selectedAiring: null,
            selectedRecurringSong: null,
            cueSheetRecordData: null,
            airingList: [],
            isVersion: false,
            isFetchingVersionDetails: false,
            cueHeaderLength: null,
            headingsData: null,
            statusType: null,
            isSubmit: false,
            openNotification: null,
            showBasedSeasonList: [],
            selectedShowSeasons: [],
            episodeOptions: [],
            isFetchingSeason: false,
            isFetchingEpisode: false,
            showSeasonComposerModal: false,
            composerOptions: [],
            composerDetalis: [],
            compositionOption: [],
            compositionList: [],
            recurringList: [],
            isPreviewLoader: false,
            cueSheetPermissions:[],
            showImportCueSheet: false,
            divisisionData:null,
            currentUser: null
        }
    };

    componentDidMount() {
        document.title = 'Cue Sheets'
        if(this.props?.userContext?.active_tenant?.tenant_id) {
            this.getShowDetails("SHOW", { id: 'show_id', name: 'show_name' }, "showList", '');
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.userContext?.active_tenant?.tenant_id != this.props?.userContext?.active_tenant?.tenant_id) {
            this.getShowDetails("SHOW", { id: 'show_id', name: 'show_name' }, "showList", '');
    }}

    handleCuesheetApis(episode_id) {
        this.setState({ currentUser: null}, () => {
            this.getCueSheetHeader(episode_id);
            this.getRecurringCueDetails();
            this.getComposerDetails();
            this.getEntity('musicUses', 'MUSIC_USES', '');
        })
    }

    getEntity = (entity, entityType, searchString) => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=${entityType}&searchString=${searchString}`, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let cueSheetConfig = { ...this.state.config }
                if (entityType === "MUSIC_USES") {
                    let musicUses = response?.data?.map(item => ({
                        text: item.abbreviation,
                        value: item.music_uses_id,
                    }));
                    cueSheetConfig.headings[6].selectOptions = musicUses
                    this.setState({config: cueSheetConfig })
                }
            })
    }

    getCueSheetHeader = (episode_id, headerId = null) => {
        this.setState({isFetchingCueSheetDetails:true})
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/showSeasonEpisodeCueSheetHeaders?show_season_id=${this.state.cueSheetDetails?.selectedSeason}&show_season_episode_id=${episode_id}`,
            this.props.userContext?.active_tenant?.tenant_id, this.state?.division_id)
            .then(response => {
                this.setState({ cueHeaderLength: response.data.length }, () => {
                    if (this.state.cueHeaderLength > 0) {
                        let airingOptions = response.data.map((item) => ({ label: item.airing, value: item.cue_sheet_header_id }))
                        this.setState({
                            headingsData: response.data,
                            airingList: airingOptions,
                            cueSheetHeaderDetails: headerId ? response.data?.find(item => item?.cue_sheet_header_id === headerId) : response.data[0],
                            selectedAiring: headerId ? headerId : response.data[0]?.cue_sheet_header_id
                        })
                        this.getCueSheetDetails(headerId ? headerId : response.data[0]?.cue_sheet_header_id)
                    }
                    else {
                        this.setState({
                            airingList: [], headingsData: null, cueSheetHeaderDetails: null, selectedAiring: null, cueSheetRecordData: null,
                            page: 0,
                            renderList: [],
                            isFetchingCueSheetDetails: false, isSubmit: false
                        }, this.getCompositionList)
                    }
                })
            })
    }

    getCueSheetHeaderId = (id = null) => {
        this.setState({ selectedAiring: id })
        this.getCueSheetHeader(this.state.cueSheetDetails?.show_season_episode_id, id)
    }

    getCueSheetDetails = (cueSheetHeaderId, loader = true) => {
        this.setState({ isFetchingCueSheetDetails: loader, selectedAiring: cueSheetHeaderId })
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/showSeasonEpisodeCueSheetRecords?show_season_id=${this.state.cueSheetDetails.selectedSeason}&show_season_episode_id=${this.state.cueSheetDetails?.show_season_episode_id}&cue_sheet_header_id=${cueSheetHeaderId}`,
            this.props.userContext?.active_tenant?.tenant_id, this.state?.division_id)
            .then(response => {
                let data = { ...response.data[0] }
                let config = { ...this.state.config }
                if (data?.is_submit && this.state.cueSheetPermissions?.find(item => item?.permission_name === 'Cue Sheet Access - Initialized')?.edit === 1) {
                    config.actions = ["pen", 'copy', 'times']
                }
                else {
                    config.actions = [];
                }
                config.disableSelectCheckbox = response.data?.length > 0 ? 
                                !(data?.is_submit && this.state.cueSheetPermissions?.find(item => item?.permission_name === 'Cue Sheet Access - Initialized')?.edit === 1) :
                                !this.state.cueSheetPermissions?.find(item => item?.permission_name === 'Cue Sheet Access - Initialized')?.edit === 1
                if(data?.cue_sheet_records?.length > 0){
                    data?.cue_sheet_records?.sort((a,b) => a?.cue_no?.localeCompare(b?.cue_no))
                    for(let i = 0 ; i < data?.cue_sheet_records?.length ; i++) {
                        data.cue_sheet_records[i].min = data.cue_sheet_records[i].duration  ? data.cue_sheet_records[i].duration?.split(':')[0] : null
                        data.cue_sheet_records[i].sec =  data.cue_sheet_records[i].duration  ? data.cue_sheet_records[i].duration?.split(':')[1] : null
                        data.cue_sheet_records[i].colon =  ':'
                        data.cue_sheet_records[i].editing = false
                        delete data.cue_sheet_records[i]?.duration 
                    }
                    let numberOfCues = data?.cue_sheet_records?.length - 1;
                    let lastRecordCueNo = data?.cue_sheet_records[numberOfCues]?.cue_no;
                    if (!isNaN(lastRecordCueNo)) {
                        this.setState({ currentUser: {cue_no: String(parseInt(lastRecordCueNo) + 1), colon : ':' }})
                    }else {
                        this.setState({ currentUser: { colon : ':' }})
                    }
                } else {
                    this.setState({ currentUser: { colon : ':' }})
                }               
                this.setState({
                    cueSheetRecordData: response.data?.length > 0 ? data : null,
                    page: 0,
                    config: config ,
                    renderList: handlePaginationList(0, this.state.rowsPerPage, data?.cue_sheet_records),
                    isFetchingCueSheetDetails: false, isSubmit: false
                }, () => {
                    this.getCompositionList()
                })

            })
    }


    getRecurringCueDetails = () => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/showSeasonRecurringCues?show_season_id=${this.state.cueSheetDetails?.selectedSeason}`, this.props.userContext?.active_tenant?.tenant_id, this.state.division_id)
            .then(response => {
                let showSeasonRecurringCues = response?.data[0];
                this.setState({
                    showSeasonRecurringCues: showSeasonRecurringCues,
                    recurringList: showSeasonRecurringCues?.show_season_recurring_cue_list?.map(item => ({
                        label: item?.composition,
                        value: item?.show_season_recurring_cue_id,
                    }))
                })
            })
    }

    getComposerDetails = () => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=${"SHOW_SEASON_COMPOSERS"}&searchString=${this.state.cueSheetDetails?.selectedSeason}`, this.props.userContext?.active_tenant?.tenant_id, this.state.division_id)
            .then(response => {
                let composerListOptions = response.data?.map(item => {
                    let newItem = { ...item }
                    newItem.label = item.composer,
                    newItem.value = item.show_season_composer_id
                    return newItem
                })
                let cueSheetRecordConfig = { ...this.state.config }
                cueSheetRecordConfig.newRowSelectOptions[4].addRowOptions = composerListOptions
                this.setState({ config: cueSheetRecordConfig, composerOptions: composerListOptions })
            })
    }

    getCompositionList = () => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=${"CUE_SHEET_COMPOSITION"}&searchString=${this.state.cueSheetDetails?.show_season_episode_id}`, this.props.userContext?.active_tenant?.tenant_id, this.state?.division_id)
            .then(response => {
                let existingSongs = this.state.cueSheetRecordData?.cue_sheet_records?.map(item => item?.song_name);
                let compositionData = response.data
                ?.filter(item => item.status.split(",").includes("Using"))
                ?.map(item => ({
                    label: item.song_title,
                    value: item.song_id,
                    composition: item.song_title
                }));
                let distinctOptions = compositionData?.filter(
                    item => !existingSongs?.includes(item?.composition)
                );
                let cueSheetRecordConfig = { ...this.state.config };
                cueSheetRecordConfig.newRowSelectOptions[2].addRowOptions = distinctOptions;
                this.setState({ config: cueSheetRecordConfig });
            });
    }


    formatCueSheetDetails = (dataItem, mode = null) => {
        this.setState({ mode: mode })
        let cueSheetDetails = { ...this.state.cueSheetDetails }
        cueSheetDetails.cue_sheet_header_id = this.state.selectedAiring || null
        cueSheetDetails.show_season_id = this.state.cueSheetDetails?.selectedSeason
        cueSheetDetails.show_season_episode_id = this.state.cueSheetDetails?.show_season_episode_id
        cueSheetDetails.show_id = this.state.cueSheetDetails?.selectedShowForSearch?.value
        cueSheetDetails.season_id = this.state.showBasedSeasonList?.find(item => item?.value === this.state.cueSheetDetails?.selectedSeason)?.season_id
        cueSheetDetails.composer = dataItem?.composer?.toUpperCase() || null;
        cueSheetDetails.publisher = dataItem?.publisher?.toUpperCase() || null;
        cueSheetDetails.is_arr = dataItem?.is_arr ? 1 : 0;
        cueSheetDetails.min = dataItem?.min || null;
        cueSheetDetails.sec = dataItem?.sec || null;
        cueSheetDetails.how_used = mode === "add_recurring_cue" ? dataItem?.how_used : dataItem?.how_used?.value || null;
        cueSheetDetails.cue_sheet_record_id = dataItem?.cue_sheet_record_id ? dataItem?.cue_sheet_record_id : null;
        cueSheetDetails.cue_no = mode === "copy" ? dataItem?.cue_no  ?  `${dataItem?.cue_no}-A`?.toUpperCase() : null : dataItem?.cue_no?.toUpperCase()|| null;
        cueSheetDetails.composition = dataItem?.composition?.toUpperCase() || null;
        if (mode === "delete") {
            cueSheetDetails.is_delete = 1;
        } else if (mode === "copy" || mode === "add_recurring_cue") {
            cueSheetDetails.cue_sheet_record_id = null;
        }
        delete cueSheetDetails.selectedShowForSearch
        return cueSheetDetails;
    }

    postCueSheetDetails = (payload, postFlag) => {
        this.setState({ isSubmit: postFlag })
        payload.duration = `${payload?.min ? payload.min : ""}:${payload.sec ? payload.sec : ""}`
        delete payload.min
        delete payload.sec
        ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl +
            `/showSeasonEpisodeCueSheetRecords`, payload, this.props.userContext?.active_tenant?.tenant_id, this.state?.division_id)
            .then(response => {
                if (response) {
                    if (response.data.error) {
                        this.setState({ isSubmit: false, openNotification: 'error' })
                    } else {
                        if (this.state.selectedAiring === null) {
                            this.getCueSheetHeader(this.state.cueSheetDetails?.show_season_episode_id)
                        } else {
                            this.getCueSheetDetails(this.state.selectedAiring, false);
                        }
                        this.setState({ openNotification: 'success' })
                    }
                }
            }),
            (err) => {
                this.setState({ isSubmit: false, openNotification: 'error' })
            }
    }


    handleshowRecurringModal = () => {
        this.setState({ showRecurringModal: true })
    }

    handleHideRecurringModal = () => {
        this.setState({ showRecurringModal: false })
    }

    handleOpenEditDetails = (event) => {
        this.setState({ setModal: event.currentTarget })
    }
    handleshowSeasonComposerModal = () => {
        this.setState({ showSeasonComposerModal: true })
    }

    handleHideShowSeasonComposerModal = () => {
        this.setState({ showSeasonComposerModal: false })
    }


    handleStatus = (statusType, loaderType) => {
        let postJson = { ...initialStatusConfig };
        postJson.show_season_episode_id = this.state.cueSheetDetails?.show_season_episode_id;
        postJson.cue_sheet_header_id = this.state.selectedAiring;
        postJson.status = statusType;
        this.setState({ [loaderType]: true })
        ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/showSeasonEpisodeCueSheetStatus`,
            postJson, this.props.userContext?.active_tenant?.tenant_id, this.state?.division_id)
            .then(
                (response) => {
                    if (response.data.error) {
                        this.setState({ [loaderType]: false , openNotification:'error'})
                    } else {
                        this.setState({ [loaderType]: false , openNotification:'success'})
                        this.getCueSheetDetails(this.state.selectedAiring);
                    }
                },
                (err) => {
                    this.setState({ [loaderType]: false, openNotification:'error' })
                    
                }
            )
    }


    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
            renderList: handlePaginationList(newPage, this.state.rowsPerPage, this.state.cueSheetRecordData?.cue_sheet_records)
        });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
            renderList: handlePaginationList(0, parseInt(event.target.value, 10), this.state.cueSheetRecordData?.cue_sheet_records)
        });
    }

    handleAddRecurringCue = () => {
        let recurringCue = this.state.showSeasonRecurringCues?.show_season_recurring_cue_list?.find(item => item?.show_season_recurring_cue_id === this.state.selectedRecurringSong);
        let payload = this.formatCueSheetDetails(recurringCue, "add_recurring_cue");
        this.postCueSheetDetails(payload, 'newItemPost');
        this.setState({ selectedRecurringSong: null })
    }



    handleChange = (field, value) => {
        this.setState((prevState) => ({
            cueSheetDetails: {
                ...prevState.cueSheetDetails,
                [field]: value,
            },
        }));
    };

    getSeasonsBasedOnShow = (newValue) => {
        this.setState({ isFetchingSeason: true })
        ClearTrackService.getData(
            Constants.ClearTrackServiceBaseUrl + `/seasonsBasedOnShows?show_id=${newValue.value}`,
            this.props?.userContext?.active_tenant?.tenant_id, this.state?.division_id
        ).then(
            (response) => {
                let formattedList = response.data[0]?.seasons?.map(item => ({ label: item?.season_name, value: item?.show_season_id, season_id: item?.season_id }))
                this.setState({ showBasedSeasonList: formattedList, isFetchingSeason: false })
            })
            .catch(error => {
                console.log("error--", error)
            })

    }

    getShowDetails = (entity, config, node, searchString) => {
        if (this.state.searchCancelToken != null)
            this.state.searchCancelToken.cancel("Operation canceled due to new request");
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken });
        this.setState({ isShowFetching: true })
        ClearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entity}&searchString=${searchString}`, cancelToken, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map((item) => ({
                    value: item[config.id],
                    text: item[config.name],
                    division_id: item.division_id
                }));
                this.setState({ isShowFetching: false, showList: formattedList })
            },
                (err) => {
                    console.log("Error in fetching show data:", err)
                })
    }

    getShowBasedEpisodes = (showSeasonId) => {
        this.setState({ isFetchingEpisode: true })
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/showSeasonEpisodes?show_season_id=${showSeasonId}`, this.props.userContext?.active_tenant?.tenant_id, this.state?.division_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.show_season_episode_id, label: `${item.episode_number ? item.episode_number : '' }${item.episode_title ? '-'+item.episode_title : ''}` }));
                this.setState({ episodeOptions: formattedList, isFetchingEpisode: false });
            },
                (err) => {
                    console.log("Error in fetching Details:", err)
                })
    }

    prviewPdf = (is_box_upload = 1, loaderName = null) => {
        if(loaderName) {
            this.setState({[loaderName]: true})
        }
        let searchPayload = {
            show_season_id: this.state.cueSheetDetails?.selectedSeason || null,
            show_season_episode_id: this.state.cueSheetDetails.show_season_episode_id || null,
            cue_sheet_header_id: this.state.selectedAiring || null,
            is_box_upload: is_box_upload,
        };
        Object.keys(searchPayload).map((obj) => {
            if (searchPayload[obj] === undefined) {
                searchPayload[obj] = null;
            }
        });
        let encryptTedStr = btoa(JSON.stringify(searchPayload));
        let load = {
            payload: "exportPdf",
            reportName: "rptCueSheetsReport",
            fileName: `cueSheetsReport`,
            searchJson: encryptTedStr,
            showHtml: 0,
            encryptionType: 1,
            tenantId: this.props?.userContext?.active_tenant?.tenant_id,
            divisionId: this.state.division_id,
        };
        ClearTrackService.getDataWS(load).then(
            (webSocketConn) =>
            (webSocketConn.onmessage = (e) => {
                let response = JSON.parse(e.data);
                if (response?.statusCode == 200) {
                    if(loaderName) {
                        this.setState({[loaderName]: false})
                    }
                    if(is_box_upload === 0) {
                        this.downloadUrl(response?.body);
                    }
                    if(loaderName === 'isNewCSLoader') {
                        this.setState({openNotification:'success'})
                    }
                }
            })
        );
    }

    downloadUrl = (url) => {
        if (url) {
            fetch(url)
                .then((response) => response.blob())
                .then((blob) => {
                    const link = document.createElement("a");
                    link.href = url;
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                });
        }
    };


    getCueSheetDefaultDataPermission () {
        return this.state.cueSheetRecordData ? !this.state.cueSheetRecordData?.status: true
    }

    editClicked = (id, list) => {
        let renderList = list.map((item) => {
            if (item.cue_sheet_record_id == id) {
                let use_id = item?.how_used
                item['how_used'] = {text: item?.uses, value: item?.how_used}
                item['uses'] = use_id
                item['editing'] = true
            } else {
                item.editing = false
            }
            return item
        })
        this.setState({ renderList: renderList });
    }

    render() {
        return (
            <div>
                {(this.props?.userContext?.active_tenant?.tenant_id && this.props?.userContext?.active_tenant?.division_role?.some(item => item?.role_name === 'Music Editor')) ?
                <div>
                    <NotificationComponent
                        open={this.state.openNotification?.length > 0 ? true : false}
                        message={this.state.openNotification === 'success' ? 'Changes saved sucessfully' : this.state.openNotification === 'error' ? 'Saving Changes failed' : ''}
                        severity={this.state.openNotification || ''}
                        handleResetNotify={() => this.setState({ openNotification: null })}
                    />
                    <MDBRow>
                        {(this.state.cueSheetDetails?.selectedShowForSearch && this.state.cueSheetDetails?.selectedSeason && this.state.cueSheetDetails?.show_season_episode_id) ? " " :
                            <span>Please select Show, Season and Episode</span>}
                        <MDBCol md={3} className="inline-block">
                            <SearchSelectField
                                label="Program"
                                options={this.state.showList || []}
                                loading={this.state.isShowFetching}
                                width="80%"
                                multiple={false}
                                detail_selected={this.state.cueSheetDetails?.selectedShowForSearch ? this.state.cueSheetDetails.selectedShowForSearch : null}
                                searchSelect={true}
                                valueSelected={(e, newValue) => {
                                    if (newValue) {
                                        this.setState({ division_id: newValue?.division_id, showBasedSeasonList: [], episodeOptions: [] , divisisionData : this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id === newValue?.division_id)}, () => {
                                            this.getSeasonsBasedOnShow(newValue)
                                            this.handleChange('selectedShowForSearch', newValue)
                                            this.handleChange('selectedSeason', null)
                                            this.handleChange("show_season_episode_id", null);
                                            this.handleChange("episode_title", null)
                                            this.setState({cueSheetPermissions : this.state.divisisionData?.permissions?.filter(ele => ele?.permission_group_name ===  `Cue Sheet Access - ${this.state.divisisionData?.division_name} Cue Sheets`)})
                                        })
                                    } else {
                                        this.setState({ division_id: null, showBasedSeasonList: [], episodeOptions: [], cueSheetPermissions:[] , divisisionData:null}, () => {
                                            this.handleChange('selectedShowForSearch', null)
                                            this.handleChange('selectedSeason', null)
                                            this.handleChange("show_season_episode_id", null);
                                            this.handleChange("episode_title", null)
                                        })
                                    }
                                }}
                                placeholder={'- Search program -'}
                                searchText={ev => {
                                    // dont fire API if the user delete or not entered anything
                                    if (ev.target.value !== "" && ev.target.value !== null) {
                                        this.getShowDetails("SHOW", { id: 'show_id', name: 'show_name' }, "showList", ev.target.value);
                                    } else {
                                        this.setState({ isShowFetching: false })
                                    }
                                }}
                            />
                        </MDBCol>
                        <MDBCol md={3} className="inline-block">
                            {this.state.isFetchingSeason ? <CircularProgress /> :
                                <SelectField
                                    id="Season"
                                    label="Season"
                                    value={this.state.cueSheetDetails?.selectedSeason || null}
                                    placeHolderText="- Select Season -"
                                    options={this.state.showBasedSeasonList || []}
                                    onChange={(e) => {
                                        this.handleChange("selectedSeason", e.target.value);
                                        this.getShowBasedEpisodes(e.target.value),
                                            this.handleChange("show_season_episode_id", null);
                                        this.handleChange("episode_title", null)
                                    }}
                                />
                            }
                        </MDBCol>
                        <MDBCol md={3}>
                            {this.state.isFetchingEpisode ? <CircularProgress /> :
                                <SelectField
                                    label={"Episode"}
                                    width={"100%"}
                                    id={"episode"}
                                    placeHolderText="- Select Episode-"
                                    value={this.state.cueSheetDetails?.show_season_episode_id}
                                    options={this.state.episodeOptions || []}
                                    onChange={(e, newValue) => {
                                        this.setState({
                                            headingsData: [], airingOptions: [], cueSheetHeaderDetails: null,
                                            selectedAiring: null, cueSheetRecordData: null, page: 0,
                                            renderList: [],
                                            isFetchingCueSheetDetails: false, isSubmit: false
                                        }, () => {
                                            this.handleChange("show_season_episode_id", e.target.value);
                                            this.handleChange("episode_title", newValue?.props?.children)
                                            this.handleCuesheetApis(e.target.value)
                                        })
                                    }}
                                />
                            }
                        </MDBCol>
                    </MDBRow>
                    {(this.state.cueSheetDetails?.selectedShowForSearch?.value && this.state.cueSheetDetails?.selectedSeason && this.state.cueSheetDetails?.show_season_episode_id) &&
                        <div className="cueSheet row episode-clearence-container cueSheetDashBoard" id='mainGrid'>
                            <div className="cueSheet-header">
                                <div className="cuesheet-btn">
                                    <Typography className="header-cue" sx={{ width: '3%', flexShrink: 0, color: 'text.black', fontSize: '10px' }}>
                                        CueSheet
                                    </Typography>
                                    {  <div onClick={this.handleshowRecurringModal} className="label-with-cursor">
                                        <span> Recurring Cues</span>
                                    </div>}
                                    { <div onClick={this.handleshowSeasonComposerModal} className="label-with-cursor">
                                        <span> Show Season Composer</span>
                                    </div>}
                                    <div  className="cue-sheet-record-status">
                                        {this.state.cueSheetRecordData?.is_submit  && <span>Cue Sheet is pending (not yet Submitted)</span>}
                                        {this.state.cueSheetRecordData?.status === 'finalized' &&  <span>Cue Sheet finalized by {this.state.cueSheetRecordData?.finalized_by} on {this.state.cueSheetRecordData?.finalized_date}</span>}
                                        {(this.state.cueSheetRecordData?.status === 'submitted' ) && <span>Cue Sheet submitted by {this.state.cueSheetRecordData?.submitted_by} on {this.state.cueSheetRecordData?.submitted_date}</span>}
                                        {(this.state.cueSheetRecordData?.status === 'approved' ) && <span>Cue Sheet approved by {this.state.cueSheetRecordData?.approved_by} on {this.state.cueSheetRecordData?.approved_date}</span>}
                                    </div>
                                </div>
                                <MDBRow className='cuesheet-btn-3-music-editor'>
                                    <MDBCol className='flex justify-content-between'>
                                        {(this.state.selectedAiring ? (this.state?.cueSheetRecordData ? this.state?.cueSheetRecordData?.is_submit :  true) : true) &&
                                        this.state.cueSheetPermissions?.find(item => item?.permission_name === 'Cue Sheet Access - Initialized')?.edit === 1 && <BasicButton
                                                color="primary"
                                                text={"Import"}
                                                id={'imporstCueSheetRecord'}
                                                variant="contained"
                                                type="inline"
                                                onClick={() => this.setState({showImportCueSheet:true})}
                                            />}
                                    {this.state.cueSheetRecordData?.cue_sheet_records?.length > 0 && <BasicButton
                                            variant="outlined"
                                            type="inline"
                                            color="primary"
                                            text={this.state.isPreviewLoader ? <CircularProgress color="inherit" size={20} /> : "Preview"}
                                            onClick={() => this.prviewPdf(0, 'isPreviewLoader')}
                                        />}
                                        {this.state.cueSheetRecordData?.cue_sheet_records?.length > 0 && this.state?.cueSheetRecordData?.is_submit && <BasicButton
                                            variant="outlined"
                                            type="inline"
                                            color="primary"
                                            text={this.state.isNewCSLoader ? <CircularProgress color="inherit" size={20} /> : "New CS"}
                                            onClick={() => this.prviewPdf(1, 'isNewCSLoader')}
                                        />}
                                        </MDBCol>
                                </MDBRow>
                            </div>
                            <div>
                                {
                                    <div className="d-flex">
                                        <div className="cue-sheet-header-form sectionWidth">
                                            <MDBRow>
                                                <MDBCol md={2}>
                                                    <SelectField
                                                        id={"recurring-cue"}
                                                        label="Airing"
                                                        options={this.state.airingList?.length > 0 ? this.state.airingList : [{ label: 'Initial', value: 'Initial' }]}
                                                        value={this.state.airingList?.length > 0 ? this.state.selectedAiring : 'Initial'}
                                                        onChange={(e) => {
                                                            if (e.target.value !== 'Initial') {
                                                                this.getCueSheetDetails(e.target.value)
                                                                this.setState({ selectedAiring: e.target.value, cueSheetHeaderDetails: this.state.headingsData?.find(item => item?.cue_sheet_header_id === e.target.value) })
                                                            }
                                                        }}
                                                    />
                                                </MDBCol>
                                                { this.state.cueSheetPermissions?.find(item => item?.permission_name === 'Cue Sheet Access - Initialized')?.edit === 1 &&
                                                    <MDBCol md={1} className="copy-btn p-0">
                                                        {this.state.isFetchingVersionDetails ? <CircularProgress color="inherit" size={18} /> : 
                                                            <BasicButton
                                                                id={this.state.cueSheetDetails?.show_season_episode_id + this.state.selectedAiring}
                                                                variant="contained"
                                                                text="Copy"
                                                                type="inline"
                                                                color="primary"
                                                                disabled={this.state.cueSheetRecordData?.cue_sheet_records?.length === 0 || this.state.cueHeaderLength === 0 ? true : false}
                                                                onClick={(e) => this.setState({ setCopyModal: e.currentTarget })}
                                                            />
                                                        }
                                                        <Popover
                                                            id={'copy' + this.state.cueSheetDetails?.show_season_episode_id + this.state.selectedAiring}
                                                            open={this.state.setCopyModal ? true : false}
                                                            className={"pop-over"}
                                                            anchorEl={this.state.setCopyModal}
                                                            onClose={() => this.setState({ setCopyModal: null })}
                                                            anchorReference="anchorOrigin"
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'left',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                            PaperProps={{ style: { width: '25%' } }}
                                                        >
                                                            <CueSheetCopyVersion
                                                                show_season_id={this.state.cueSheetDetails?.selectedSeason}
                                                                show_season_episode_id={this.state.cueSheetDetails?.show_season_episode_id}
                                                                show_id={this.state.cueSheetDetails?.selectedShowForSearch?.value}
                                                                season_id={this.state.showBasedSeasonList?.find(item => item?.value === this.state.cueSheetDetails?.selectedSeason)?.season_id}
                                                                cueSheetHeaderDetails={this.state.cueSheetHeaderDetails}
                                                                cue_sheet_header_id={this.state.selectedAiring}
                                                                handleClose={() => this.setState({ setCopyModal: null })}
                                                                availableAiring={this.state.airingList?.map(item => item?.label)}
                                                                division_id={this.state.division_id}
                                                                notificationComponent={(value, msg=null) => this.setState({ openNotification: value, anyErrorMessage: msg })}
                                                                toggleRefresh={() => this.getCueSheetHeader(this.state.cueSheetDetails?.show_season_episode_id)}
                                                            />
                                                        </Popover>
                                                    </MDBCol>
                                                }
                                                {this.props?.permissionArrCueSheets?.find(item => item?.permission_name === 'Cue Sheet Access - Initialized')?.view === 1 && <MDBCol md={2} className="p-0">
                                                <BasicLabel text={'Time'} />
                                                <span className="basic-text">
                                                    {this.state.cueSheetHeaderDetails?.cue_sheet_time || "-"}
                                                </span>
                                            </MDBCol>}
                                                <MDBCol md={1} className="p-0 notes-file-size">
                                                    <BasicLabel text={'Notes'} />
                                                    <MDBTooltip
                                                        tag="a"
                                                        title={this.state.cueSheetHeaderDetails?.cue_sheet_notes}
                                                        placement="right"
                                                    >
                                                        <MDBIcon icon="file" size='sm' />
                                                    </MDBTooltip>
                                                </MDBCol>
                                                {  this.state?.cueSheetRecordData?.is_submit &&
                                                    this.state.cueSheetPermissions?.find(item => item?.permission_name === 'Cue Sheet Access - Initialized')?.edit === 1  && this.state.cueSheetRecordData?.cue_sheet_records?.length > 0  &&
                                                    <MDBCol md={2} className="edit-details-btn p-0">
                                                        <span>
                                                            <BasicButton
                                                                variant="contained"
                                                                text="Edit Details"
                                                                type="inline"
                                                                color="primary"
                                                                onClick={this.handleOpenEditDetails}
                                                            />
                                                            <Popover
                                                                id={"edit-detail"}
                                                                open={this.state.setModal ? true : false}
                                                                className={"pop-over"}
                                                                anchorEl={this.state.setModal}
                                                                onClose={() => this.setState({ setModal: null })}
                                                                anchorReference="anchorOrigin"
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'left',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                                PaperProps={{ style: { width: '25%' } }}
                                                            >
                                                                <CueSheetEditDetails
                                                                    show_season_id={this.state.cueSheetDetails?.selectedSeason}
                                                                    show_season_episode_id={this.state.cueSheetDetails?.show_season_episode_id}
                                                                    show_id={this.state.cueSheetDetails?.selectedShowForSearch?.value}
                                                                    season_id={this.state.showBasedSeasonList?.find(item => item?.value === this.state.cueSheetDetails?.selectedSeason)?.season_id}
                                                                    cueSheetHeaderDetails={this.state.cueSheetHeaderDetails}
                                                                    cue_sheet_header_id={this.state.selectedAiring}
                                                                    handleClose={() => this.setState({ setModal: null })}
                                                                    toggleRefresh={this.getCueSheetDetails}
                                                                    musicEditorView={true}
                                                                    getCueSheetHeader={this.getCueSheetHeaderId.bind(this)}
                                                                    division_id={this.state.division_id}
                                                                    notificationComponent={(value) => this.setState({ openNotification: value })}
                                                                />
                                                            </Popover>
                                                        </span>

                                                    </MDBCol>
                                                }
                                            </MDBRow>

                                        </div>
                                        <div className='status-section-width'>
                                            <MDBRow className='submit-margin-top'>
                                                {
                                                    <>
                                                        {
                                                            this.state.cueSheetRecordData?.is_submit && this.state.cueSheetPermissions?.find(item => item?.permission_name === 'Submit (when Initialized)')?.view === 1 &&
                                                            <MDBCol md={2} xl={2} className="p-0 ml-2">
                                                                <BasicButton
                                                                    variant="contained"
                                                                    type="inline"
                                                                    color="primary"
                                                                    className="submit-btn"
                                                                    text={this.state.isSubmitted ? <CircularProgress color="inherit" size={18} /> : "Submit"}
                                                                    onClick={() => this.handleStatus('submitted', 'isSubmitted')}
                                                                />
                                                            </MDBCol>
                                                        }
                                                        {this.state.cueSheetRecordData?.is_unsubmit  && this.state.cueSheetPermissions?.find(item => item?.permission_name === 'Un-submit button (when Submitted)')?.view === 1 && 
                                                            <MDBCol md={3} xl={3} className="p-0 ml-1">
                                                                <BasicButton
                                                                    variant="outlined"
                                                                    className="unsubmit-btn"
                                                                    type="inline"
                                                                    text={this.state.isUnSubmitted ? <CircularProgress color="inherit" size={18} /> : "Un-Submit"}
                                                                    onClick={() => this.handleStatus('unsubmitted', 'isUnSubmitted')}
                                                                />
                                                            </MDBCol>
                                                        }
                                                    </>
                                                }
                                            </MDBRow>
                                        </div>

                                    </div>
                                }
                            </div>

                            {this.state.isFetchingCueSheetDetails ?
                                <div className="ContentLoader">
                                    <CircularProgress />
                                </div> :
                                <MDBRow className={"cueSheetTable"} id="episode-clearance-table">
                                    <CuesheetTableComponet
                                        addItemToList={this.state.selectedAiring ? 
                                            this.state.cueSheetRecordData ? 
                                            ( this.state.cueSheetRecordData?.is_submit && this.state.cueSheetPermissions?.find(item => item?.permission_name === 'Cue Sheet Access - Initialized')?.edit === 1 ):
                                            this.state.cueSheetPermissions?.find(item => item?.permission_name === 'Cue Sheet Access - Initialized')?.edit === 1 : 
                                            this.state.cueSheetPermissions?.find(item => item?.permission_name === 'Cue Sheet Access - Initialized')?.edit === 1 }
                                        renderList={this.state.renderList}
                                        editClicked={this.editClicked.bind(this)}
                                        config={this.state.config}
                                        formatCueSheetDetails={this.formatCueSheetDetails.bind(this)}
                                        postCueSheetDetails={this.postCueSheetDetails}
                                        cueHeaderLength={this.state.cueHeaderLength}
                                        showSeasonEpisodeCueSheetRecords={this.state.cueSheetRecordData}
                                        composerDetalis={this.state.composerDetalis}
                                        composerOptions={this.state.composerOptions}
                                        CuesheetDashboard={true}
                                        isSubmit={this.state.isSubmit}
                                        showSeasonId={this.state.cueSheetDetails?.selectedSeason}
                                        showSeasonEpisodeId={this.state.cueSheetDetails?.show_season_episode_id}
                                        show_id={this.state.cueSheetDetails?.selectedShowForSearch?.value}
                                        currentUser={this.state.currentUser}
                                        season_id={this.state.showBasedSeasonList?.find(item => item?.value === this.state.cueSheetDetails?.selectedSeason)?.season_id}
                                    />
                                    <MDBRow className="pagination-section">
                                        {(this.state.selectedAiring ?
                                            (this.state.cueSheetRecordData ? this.state.cueSheetRecordData?.is_submit : true) && 
                                            this.state.cueSheetPermissions?.find(item => item?.permission_name === 'Cue Sheet Access - Initialized')?.edit === 1 &&
                                            this.state.recurringList?.length > 0 : this.state.recurringList?.length > 0) ? <MDBCol className="recurring-cue">
                                            <MDBRow>
                                                <MDBCol md={5}>
                                                    <SelectField
                                                        id={"recurring-cue"}
                                                        defaultMenuText={"- Add a Recurring Cue -"}
                                                        options={this.state.recurringList || []}
                                                        value={this.state.selectedRecurringSong || ""}
                                                        onChange={(e) => this.setState({ selectedRecurringSong: e.target.value })}
                                                    />
                                                </MDBCol>
                                                    <MDBCol className="p-0 chevron">
                                                        <MDBIcon
                                                            icon={"plus-circle"}
                                                            onClick={this.handleAddRecurringCue}
                                                            disabled={!this.state.selectedRecurringSong}
                                                        />
                                                    </MDBCol>
                                            </MDBRow>
                                        </MDBCol> : <MDBCol md={7}></MDBCol>}
                                        <MDBCol md={5}>
                                            <TablePagination
                                                component="div"
                                                count={this.state.cueSheetRecordData?.cue_sheet_records ? this.state.cueSheetRecordData?.cue_sheet_records?.length : 0}
                                                page={this.state.page}
                                                onChangePage={this.handleChangePage}
                                                rowsPerPage={this.state.rowsPerPage}
                                                rowsPerPageOptions={[250, 300, 500, 1000]}
                                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                </MDBRow>
                            }
                            {this.state.showRecurringModal &&
                                <MainViewModalComponent
                                    open={this.state.showRecurringModal}
                                    handleClose={this.handleHideRecurringModal}
                                    headerText={"Recurring Cue for ".concat(this.state.cueSheetDetails?.selectedShowForSearch?.text + ' ' + this.state.showBasedSeasonList?.find(item => item?.value === this.state?.cueSheetDetails?.selectedSeason)?.label)}
                                    mode={""}
                                    modalName={"RecurringCue"}
                                    showSeasonId={this.state?.cueSheetDetails?.selectedSeason}
                                    showId={this.state?.cueSheetDetails?.selectedShowForSearch?.value}
                                    seasonId={this.state.showBasedSeasonList?.find(item => item?.value === this.state?.cueSheetDetails?.selectedSeason)?.season_id}
                                    showSeasonEpisodeId={this.state.cueSheetDetails?.show_season_episode_id}
                                    toggleRefresh={this.getRecurringCueDetails.bind(this)}
                                    division_id={this.state?.division_id}
                                    composerOptions={this.state.composerOptions || []}
                                />
                            }
                            {this.state.showComposerModal &&
                                <MainViewModalComponent
                                    open={this.state.showComposerModal}
                                    handleClose={() => this.setState({ showComposerModal: false })}
                                    headerText={"Composer"}
                                    mode={this.state.mode}
                                    modalName={"Add Composer"}
                                    handleNodeValue={(value) => this.setState({
                                        composerName: {
                                            value: value,
                                            node: this.state.nodeName,
                                            index: this.state.cueSheetItemIndex
                                        }
                                    })}
                                />
                            }
                            {this.state.showImportCueSheet &&
                                <MainViewModalComponent
                                    open={this.state.showImportCueSheet}
                                    handleClose={() => this.setState({ showImportCueSheet: false })}
                                    headerText={"Import Cue Sheet Records"}
                                    mode={''}
                                    modalName={"Import Cue Sheet Data"}
                                    divisionId={this.state.division_id}
                                    EpId={this.state.cueSheetDetails?.show_season_episode_id}
                                    airingType={this.state.selectedAiring ? this.state.airingList?.find(item => item?.value === this.state.selectedAiring)?.label : 'Initial'}
                                    handleSubmit={() => { 
                                        if(this.state.selectedAiring) {
                                            this.getCueSheetDetails(this.state.selectedAiring)
                                        } else {
                                            this.getCueSheetHeader(this.state.cueSheetDetails?.show_season_episode_id)
                                        }
                                        }}
                                />
                            }
                            {this.state.showPublisherModal &&
                                <MainViewModalComponent
                                    open={this.state.showPublisherModal}
                                    handleClose={() => this.setState({ showPublisherModal: false })}
                                    headerText={"Publisher"}
                                    mode={this.state.mode}
                                    modalName={"Add Publisher"}
                                    handleNodeValue={(value) => this.setState({
                                        publisherName: {
                                            value: value,
                                            node: this.state.nodeName,
                                            index: this.state.cueSheetItemIndex
                                        }
                                    })}
                                />
                            }
                            {this.state.showSeasonComposerModal &&
                                <MainViewModalComponent
                                    open={this.state.showSeasonComposerModal}
                                    handleClose={this.handleHideShowSeasonComposerModal}
                                    headerText={"Show Composer for ".concat(this.state?.cueSheetDetails?.selectedShowForSearch?.text + ' ' + this.state.showBasedSeasonList?.find(item => item?.value === this.state?.cueSheetDetails?.selectedSeason)?.label)}
                                    mode={""}
                                    modalName={"ShowSeasonComposer"}
                                    showSeasonId={this.state?.cueSheetDetails?.selectedSeason}
                                    showId={this.state?.cueSheetDetails?.selectedShowForSearch?.value}
                                    seasonId={this.state.showBasedSeasonList?.find(item => item?.value === this.state?.cueSheetDetails?.selectedSeason)?.season_id}
                                    showSeasonEpisodeId={this.state.cueSheetDetails?.show_season_episode_id}
                                    toggleRefresh={this.getComposerDetails}
                                    division_id={this.state?.division_id}
                                />
                            }
                        </div>
                    }
                </div> :
                <div>
                    Only Music Editors can access this
                </div>}
            </div>
        );
    }
});